
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
} from 'vue';
import { useI18n } from '@/services/i18n';
import moment from 'moment';
import {
  IChatMessageModel,
} from '@/models/cs/api';
import Regex from '@/models/cs/regular-expression';
import { SpeakerType } from '@/models/cs/enum';
import { DeltaInsertOp, QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import useImageCache from '@/services/image-cache-helper';
import BaseImageViewer from '../../base/BaseImageViewer.vue';

enum ImageViewType {
  Origin = 0,
  Thumbnail = 1
}
const ChatImagePrefix = '/onlinechat/image/chatting';
const TempImagePrefix = '/onlinechat/temp-image/chatting';
const ImageThumbPrefix = 'thumb';

export default defineComponent({
  name: 'ChattingMessage',
  components: {
    BaseImageViewer,
  },
  props: {
    messageData: {
      type: Object as PropType<IChatMessageModel>,
      default: () => ({}),
    },
    last: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { $i18n } = useI18n();
    const { getImageCacheMap, setImageCacheMap } = useImageCache();
    const dateFormat = (t: string) => {
      if ($i18n.locale === 'vi-vn') {
        return moment(t).utcOffset('+0700').format('HH:mm:ss');
      }
      if ($i18n.locale === 'th-th') {
        return moment(t).utcOffset('+0700').format('HH:mm:ss');
      }
      return moment(t).utcOffset('+0800').format('HH:mm:ss');
    };

    const image = reactive<{
      isZoom: boolean;
      src: string;
      path: string;
    }>({
      isZoom: false,
      src: '',
      path: '',
    });

    const chattingMessageData = reactive({
      isUser: computed(() => props.messageData.speakerType === SpeakerType.User),
      isSystem: computed(() => props.messageData.speakerType === SpeakerType.System),
      time: computed(() => (props.messageData.createdDate ? dateFormat(props.messageData.createdDate) : '')),
    });

    const isPublicImage = props.messageData.speakerType === SpeakerType.User;

    const msgPosition = computed(() => {
      const className = ['msg-sys'];
      if (props.messageData!.speakerType === SpeakerType.Player) {
        return 'msg-ply';
      }
      if (props.messageData!.speakerType === SpeakerType.User) {
        return 'msg-cs';
      }
      if (props.messageData.isUnReadSystemMessage || props.first) {
        className.push('bottom-border');
      }
      if (props.last) {
        className.push('top-border');
      }
      return className.join(' ');
    });

    const handlePrivateImageThumbnail = (path: string) => {
      const [, , , roomId, imageId] = path.split('/');
      return `${roomId}/${imageId}`;
    };
    const handlePublicImageThumbnail = (path: string) => {
      const [, , , imageId] = path.split('/');
      return `${imageId}`;
    };

    const handleQuillImage = (quillDeltas: any[]) => {
      return quillDeltas.map((item: DeltaInsertOp) => {
        if (item.attributes && item.attributes.image) {
          image.path = isPublicImage ? handlePublicImageThumbnail(item.attributes.image) : handlePrivateImageThumbnail(item.attributes.image);
          return {
            insert: {
              image: isPublicImage ? `${ChatImagePrefix}/${ImageThumbPrefix}/${image.path}` : `${TempImagePrefix}/${ImageThumbPrefix}/${image.path}`,
            },
          };
        }
        return item;
      });
    };

    const formatUrl = (link: string) => {
      if (link.indexOf('https://') < 0 && link.indexOf('http://') < 0) {
        return `https://${link}`;
      }
      return link;
    };

    const messageHtml = ((): string => {
      if (!props.messageData.message) {
        return '';
      }
      const converterOptions = {
        customCssClasses: (op: DeltaInsertOp) => {
          const classes = [];
          if (op.attributes.bold) {
            classes.push('ql-bold');
          }
          if (op.attributes.italic) {
            classes.push('ql-italic');
          }
          if (op.attributes.underline) {
            classes.push('ql-underline');
          }
          if (op.attributes.link) {
            classes.push('ql-link');
          }
          return classes.join(' ');
        },
      };
      const { message } = props.messageData;
      const encloseAtagRegex = /((href="|target="_blank">)(.*)("|<\/a))/g;
      const converter = new QuillDeltaToHtmlConverter(handleQuillImage(message.quillDeltas), converterOptions);

      converter.afterRender((groupType, htmlString) => {
        const html = htmlString.replace(/&#x2F;/g, '/');
        if (html.match(Regex.url)) {
          return html.replace(Regex.url, (match) => {
            const matchEncloseATagArr = html.match(encloseAtagRegex);
            if (matchEncloseATagArr && matchEncloseATagArr.length > 0 && matchEncloseATagArr[0].includes(match)) {
              return match;
            }
            return `<a class="ql-link" href="${formatUrl(match)}" target="_blank">${match}</a>`;
          });
        }
        return htmlString;
      });

      return converter.convert();
    })();

    const onClickImage = async (e: Event) => {
      if (!e.target || !(e.target as HTMLImageElement).src) {
        return;
      }
      const thumbnailSrc = (e.target as HTMLImageElement).src;
      const originSrc = (e.target as HTMLImageElement).src.replace(/\/thumb\//, '/');
      // 如果縮圖是破圖, 點擊會再請求一次縮圖
      if ((e.target as HTMLImageElement).naturalWidth === 0 && (e.target as HTMLImageElement).naturalHeight === 0) {
        (e.target as HTMLImageElement).src = thumbnailSrc;
      }
      image.isZoom = true;
      if (!getImageCacheMap(originSrc)) {
        await setImageCacheMap(originSrc);
      }
      image.src = getImageCacheMap(originSrc)!;
    };

    return {
      ...toRefs(chattingMessageData),
      image,
      msgPosition,
      messageHtml,
      onClickImage,
    };
  },
});
