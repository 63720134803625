<template>
  <div class="cs-full_page full_page" v-show="value">
    <div class="icon_box fixed space_between" v-if="withActionBar">
      <span class="text_btn" v-if="action">{{ action }}</span>
      <i v-if="isAllowCondensation" class="brand-icon-minus-circle icon" @click="$emit('condensation')" />
      <i class="brand-icon-cross-circle icon" @click="$emit('close')" />
    </div>
    <div class="container__inner">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import {
  computed,
  watch,
  onBeforeUnmount,
} from 'vue';

export default {
  name: 'CsFullContainer',
  setup(props, { emit }) {
    const lockBodyScroll = () => document.body.classList.add('full-open');
    const unLockBodyScroll = () => document.body.classList.remove('full-open');

    watch(() => props.modelValue, (v) => {
      if (v) {
        lockBodyScroll();
      } else {
        unLockBodyScroll();
      }
    });

    onBeforeUnmount(() => {
      unLockBodyScroll();
    });

    const value = computed({
      get: () => props.modelValue,
      set: (v) => {
        emit('update:modelValue', v);
      },
    });

    return {
      value,
    };
  },
  props: {
    withActionBar: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isAllowCondensation: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../css/style.css';
@import '../../scss/cs-style.scss';
@import '../../scss/layout/max-size-setting.scss';

%full {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.cs-full_page {
  @extend %full;
  background-color: white;
  z-index: 2600;
  :deep(.cs-action__wrapper) {
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .container__inner, ::v-slotted(.container__inner) {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    &:nth-last-child(2) { // 有 FullContainer fix button 裡面的東西要留 fix button 的高度
      margin-bottom: 80px;
    }
    &:last-child { // 沒有 FullContainer fix button 裡面的東西要貼底
      margin-bottom: 0;
    }
    .datepicker__wrapper {
      margin-top: 0;
    }
  }
  .footer_box{
    &.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px 30px;
      background-color: #FFF;
      z-index: 11;
    }
  }
}

.space_between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  // FullPage icon box word
  .text_btn {
    z-index: 12;
    color: #000000;
    margin-left: 8px;
    font-weight: bold;
    flex: 1;
  }
}

.close_btn {
  z-index: 1;
  position: fixed;
  top: 12px;
  left: 12px;
  img {
    -webkit-filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
  }
}

.icon_box {
  z-index: 2021;
  position: fixed;
  top: 0;
  right: 0;
  padding: 8px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .icon {
    z-index: 12;
    font-size: 24px;
    &.brand-icon-cross-circle {
      color: #d90101;
    }
    &+i {
      margin-left: 12px;
    }
    &:last-of-type {
      margin-right: 8px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 1px 0 #737880;
  }
  &.space_between {
    justify-content: space-between;
  }
}
</style>
