
import { computed } from 'vue';
import { CsStatus } from '@/models/cs/enum';

export default {
  name: 'MinimizedStatusBar',
  setup(props: any) {
    const barText = computed(() => ((props.status === CsStatus.Terminated || props.status === CsStatus.RoomClosed) ? 'Chatting_Chat_Closed' : 'Chatting_Return_Chat'));
    return {
      barText,
    };
  },
  props: {
    unReadCount: {
      type: Number,
    },
    status: {
      type: Number as () => CsStatus,
    },
  },
};
