<template>
  <div class="cs-leaved">
    <div class="cs-leaved-wrapper">
      <div class="cs-leaved-image">
        <img src="@/assets/cs/customService.svg" />
      </div>
      <div class="cs-leave-content-wrapper">
        <p class="cs-leaved-content">{{ $t('Common_Leaved_Message') }}</p>
        <p class="cs-leaved-sub-content">{{ $t('Common_Leaved_Close') }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cs-leaved {
  width: 100%;
  height: 100%;
  .cs-leaved-wrapper {
    height: 433px;
    padding-top: 220px;
    .cs-leaved-image {
      display: inline-block;
      width: 100%;
      height: 128px;
      text-align: center;
    }
    .cs-leaved-content {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      padding-top: 30px;
    }
    .cs-leaved-sub-content {
      text-align: center;
      padding-top: 10px;
    }
    .cs-leave-content-wrapper {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
