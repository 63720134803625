<script>
import { SurveyQuestionType } from '@/models/cs/enum';
import { ISurveyQuestionResponse } from '@/models/cs/api';

export default {
  name: 'SurveyQuestionMixin',
  emits: ['answer'],
  data: () => ({
    answer: null,
    blurred: false,
  }),
  created() {
    this.$emit('answer', { index: this.index - 1, allow: !this.question.isRequired });
  },
  computed: {
    isAllowSubmit() {
      let isAllow = false;
      if (this.question.surveyQuestionType === SurveyQuestionType.MultiChoice) {
        isAllow = (this.question.isRequired && this.answer.length > 0) || !this.question.isRequired;
      } else {
        isAllow = !this.question.isRequired || !!this.answer;
      }
      this.$emit('answer', { index: this.index - 1, allow: isAllow });
      return isAllow;
    },
    warn() {
      return { color: !this.isAllowSubmit && this.blurred ? 'red' : '' };
    },
    answerRequest() {
      const request = {
        optionText: this.answer,
      };
      return [request];
    },
  },
  methods: {
    onBlur() {
      this.blurred = true;
    },
  },
  props: {
    index: {
      type: Number,
    },
    question: {
      type: ISurveyQuestionResponse,
    },
  },
};
</script>
