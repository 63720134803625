<template>
  <div class="input" :class="renderClass">
    <transition name="fade">
      <div class="reminding_box input__reminding" v-if="isReminding">{{ reminding }}</div>
    </transition>
    <label class="input__label">
      <div class="input__title" v-if="title">
        <span class="title">
          {{ title }}
          <template v-if="!required">({{ $t('Common_Input_Optional_Default_Content') }} )</template>
        </span>
      </div>
      <div class="input__inner">
        <slot name="prefix">
          <i v-if="search" class="brand-icon-search search-icon" />
          <span v-if="prefix" class="prefix">{{ prefix }}</span>
        </slot>
        <input :type="type" :readonly="readonly" :maxlength="maxLength" v-model="bindValue" @focus="onFocus" @blur="onBlur" :class="bindValue ? 'date-placeholder-hidden' : ''" />
      </div>
      <span class="suffix-icon">
        <slot name="suffix">
          <i v-if="readonly" class="brand-icon-lock readonly-icon" />
        </slot>
      </span>
    </label>
    <span class="input__warning" v-if="isValidatorWarning">{{ $t(validatorWarning) }}</span>
    <span class="input__warning" v-if="isRequiredWarning">{{ $t(requiredWarning) }}</span>
    <span class="input__description" v-if="description">{{ $t(description) }}</span>
  </div>
</template>
<script>
import { Validators, FormControl } from '@higgs/utils';

export default {
  name: 'BaseInput',
  components: {},
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    title: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    validators: {
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    validatorWarning: {
      type: String,
      default: 'Common_Wrong_Format_Warning_Default_Content',
    },
    requiredWarning: {
      type: String,
      default: 'Common_Required_Warning_Default_Content',
    },
    reminding: {
      type: [String, Boolean],
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    keepRemindingAndWarning: {
      type: Boolean,
      default: false,
    },
    isOnlyHalfWidth: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    blured: false,
    focused: false,
    formControl: new FormControl(),
  }),
  computed: {
    renderClass() {
      return {
        warning: this.isValidatorWarning || this.isRequiredWarning,
        readonly: this.readonly,
        reminding: this.reminding,
        search: this.search,
        'is-focus': this.focused || this.bindValue,
        'without-title': !this.title && !this.search,
      };
    },
    isValidatorWarning() {
      return this.isShowWarning && this.formControl.errors && !this.formControl.errors.required;
    },
    isRequiredWarning() {
      return this.isShowWarning && this.formControl.errors && this.formControl.errors.required;
    },
    isShowWarning() {
      if (this.search) {
        return !this.valid;
      }
      return !this.valid && this.blured && !this.readonly; // && !this.search;
    },
    isReminding() {
      return (!this.isValidatorWarning && !this.isRequiredWarning && !!this.reminding) || (this.keepRemindingAndWarning && !!this.reminding);
    },
    valid() {
      if (this.formControl) {
        return this.formControl.valid;
      }
      return true;
    },
    bindValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        let temp = '';
        if (this.isOnlyHalfWidth) {
          for (let i = 0; i < v.length; i += 1) {
            let charCode = v.charCodeAt(i);
            if (charCode >= 65281 && charCode <= 65374) {
              charCode -= 65248;
            } else if (charCode === 12288) {
              // 全形空白轉半形
              charCode = 32;
            }
            temp = `${temp}${String.fromCharCode(charCode)}`;
          }
        } else {
          temp = v;
        }
        this.$emit('update:modelValue', temp);
        this.formControl.setValue(temp);
      },
    },
  },
  watch: {
    valid(v) {
      this.$emit('valid', v);
    },
  },
  created() {
    let validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.validators.length > 0) {
      validators = validators.concat(this.validators);
    }
    this.formControl = new FormControl(this.modelValue, validators);
  },
  methods: {
    onFocus(evt) {
      if (this.readonly) {
        return;
      }
      this.focused = true;
      this.blured = false;
      this.$emit('focus', evt);
    },
    onBlur(evt) {
      if (this.readonly) {
        return;
      }
      this.focused = false;
      this.blured = true;
      this.$emit('blur', evt);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
@import '../../css/style.css';
@import '../../scss/module/input.scss';
</style>
