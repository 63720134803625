<template>
  <section class="cs-with_title">
    <div class="cs-title_box" :style="warn">
      <h2 class="title-lg title-qa">{{ index }}. {{ question.description }}</h2>
    </div>
    <div class="cs-tree__body">
      <label class="cs-tree__item" v-for="opt in question.surveyQuestionOptions" :key="`${question.questionId}_${opt.optionId}`" @click="onClickOpt(opt.optionId)">
        <div class="cs-item__content">
          <div class="cs-item__label" :class="{'isChecked': answer && answer.indexOf(opt.optionId) >= 0 }">
            {{ opt.values }}
          </div>
        </div>
      </label>
    </div>
  </section>
</template>

<script>
import SurveyQuestionMixin from './SurveyQuestionMixin.vue';

export default {
  name: 'SurveyMultiChoice',
  mixins: [SurveyQuestionMixin],
  data: () => ({
    answer: [],
  }),
  methods: {
    onClickOpt(v) {
      this.blurred = true;
      const idx = this.answer.indexOf(v);
      if (idx >= 0) {
        this.answer.splice(idx, 1);
      } else {
        this.answer.push(v);
      }
    },
  },
  computed: {
    answerRequest() {
      const options = this.question.surveyQuestionOptions.filter((s) => this.answer.indexOf(s.optionId) >= 0);
      const request = options.map((s) => ({
        optionId: s.optionId,
        optionText: s.values,
      }));
      return request;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../scss/cs-style.scss';
@import '../../../css/style.css';

div.cs-tree__body {
  width: 100%;
  .cs-tree__item & {
    padding-left: 20px;
  }
  &+.cs-tree__body {
    margin-top: 40px;
  }
  .cs-tree__item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .cs-item__label {
      flex: 1;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1.2;
      padding: 16px 20px 16px 0;
      &:after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        flex-shrink: 0;
        align-self: flex-start;
        margin-left: 10px;
      }
      &.isChecked:after {
        background-color: #d90101;
        border: none;
        font-family: 'brand-icon' !important;
        content: "\e902";
        width: 24px;
        height: 24px;
        color: #FFF;
        font-size: 12.5px;
        text-align: center;
        line-height: 26px;
        flex-shrink: 0;
      }
    }
    .cs-item__content {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
  }
}

// tree 外層上下線
.cs-tree__body {
  .cs-tree__item {
    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
    }
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    &+.cs-tree__item {
      .cs-item__content {
        .cs-item__label {
          border-top: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
</style>
