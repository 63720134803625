<template>
  <div class="typing__wrapper" :class="renderClass">
    <label for="input_img">
      <div class="input__inner">
        <BaseSplitUploader v-if="!isDisabled" ref="imgUpload" @change="uploadOnChange" :route="route">
          <i class="brand-icon-photo-circle sendImg_icon"/>
        </BaseSplitUploader>
        <i v-else class="brand-icon-photo-circle sendImg_icon"/>
      </div>
    </label>
    <textarea
      class="input_text"
      :placeholder="$t(placeholder)"
      v-model="text"
      @keydown="handleKeydown"
      @keyup="handleKeyup"
      :readonly="isDisabled"
      ref="textarea"
      rows="1"
      maxlength="500"
    />
    <i class="brand-icon-send-message sendText_icon" @click="onSendText"/>
  </div>
</template>

<script>
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Regex from '@/models/cs/regular-expression';
import { useState } from '@/store/state';
import { chatApiHandler } from '@/services/chat-api-handler';
import { useI18n } from '@/services/i18n';
import BaseSplitUploader from '@/components/base/BaseSplitUploader.vue';

export default {
  name: 'ChattingSender',
  components: { BaseSplitUploader },
  setup(props) {
    const { modules } = useState();
    const { $t } = useI18n();
    const currentInstance = getCurrentInstance();
    const { $warn, $socketFactory } = currentInstance?.appContext.config.globalProperties;
    const wsSender = $socketFactory.createDebounceSender(300, value => $socketFactory.ws?.send('PreviewMessage', { text: value }));
    const wsSenderDisposed = wsSender.build().start();
    const data = reactive({
      text: '',
      offset: 0,
      hasTouchScreen: false,
      roomId: computed(() => modules.chatting.state.roomId),
      route: computed(() => `${window.location.origin}/onlinechat/api/image/upload`),
      placeholder: computed(() => (props.isDisabled ? 'Chatting_StopChat' : 'Chatting_EnterText')),
      renderClass: computed(() => {
        if (props.isDisabled) {
          return 'type-stop';
        }
        if (!data.text) {
          return 'type-no-word';
        }
        return '';
      }),
    });

    const textarea = ref(null);
    const imgUpload = ref(null);

    data.hasTouchScreen = (() => {
      if ('msMaxTouchPoints' in navigator) {
        return navigator.msMaxTouchPoints > 1;
      }
      if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 1) {
        const mQ = window.matchMedia('(pointer: coarse)');
        return mQ.matches;
      }
      const UA = navigator.userAgent;
      return /\b(BlackBerry|webOS|iPhone|IEMobile|Opera Mini)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    })();

    onMounted(() => {
      data.offset = textarea.value.offsetHeight - textarea.value.clientHeight;
    });

    onBeforeUnmount(() => wsSenderDisposed.stop());

    const resize = () => {
      textarea.value.style.height = 'auto';
      textarea.value.style.height = `${textarea.value.scrollHeight + data.offset}px`;
    };

    const send = async (request) => {
      if (props.isDisabled) {
        return;
      }
      chatApiHandler.sendMessage(request);
      data.text = '';
      await nextTick();
      resize();
    };

    const formatUrl = (link) => {
      if (link.indexOf('https://') < 0 && link.indexOf('http://') < 0) {
        return `https://${link}`;
      }
      return link;
    };

    const onSendText = () => {
      if (!data.text) {
        return;
      }
      if (data.text.length > 500) {
        $warn({
          content: $t('Chatting_TextLimit_Warning'),
          confirmButtonText: $t('Common_Confirm'),
        });
        return;
      }
      const arr = data.text.split(Regex.url);
      const quillDeltas = arr.filter((v) => v).map((v) => {
        const deltaObj = {
          insert: v,
        };
        if (v.match(Regex.url)) {
          deltaObj.attributes = {
            link: formatUrl(v),
          };
        }
        return deltaObj;
      });
      const request = {
        roomId: data.roomId,
        message: { quillDeltas },
      };
      send(request);
    };

    const handleKeydown = (event) => {
      if (props.isDisabled) {
        return;
      }
      const altKey = event.ctrlKey || event.shiftKey || event.metaKey;
      if ((event.keyCode === 13 || event.key === 'Enter') && altKey) {
        event.preventDefault();
        data.text += '\n';
      } else if (event.key === 'Enter') {
        if (data.hasTouchScreen) {
          event.preventDefault();
          data.text += '\n';
          return;
        }
        onSendText();
        event.preventDefault();
      }
    };

    const handleKeyup = () => {
      if (props.isDisabled) {
        return;
      }
      resize();
    };

    const uploadOnChange = (v) => {
      if (!v.imageId) {
        return;
      }
      const request = {
        roomId: data.roomId,
        message: {
          quillDeltas: [
            {
              insert: '\n',
              attributes: {
                image: v.imageId,
              },
            },
          ],
        },
      };
      send(request);
      imgUpload.value.remove(v.uniqueIdentifier);
    };

    watch(() => data.text, value => wsSender.fire(value));

    return {
      ...toRefs(data),
      onSendText,
      send,
      imgUpload,
      textarea,
      uploadOnChange,
      handleKeydown,
      handleKeyup,
    };
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../css/style.css';
@import '../../../scss/cs-style.scss';

.sendImg_icon {
  font-size: 32px;
}

.sendText_icon {
  font-size: 32px;
  color: #d90101;

  &::before {
    font-size: inherit;
    color: inherit;
  }
}

.typing__wrapper {
  width: 100%;
  height: auto;
  background-color: #FFF;
  padding: 7px;
  display: flex;
  border-top: 1px solid #737880;
  align-items: flex-end;

  .input__inner {
    height: 33px;
  }

  .input_text {
    flex: 1;
    margin: 0 10px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 4px 12px;
    overflow-y: visible;
    outline: none;
    -webkit-appearance: none;
    resize: none;
    font-size: 16px;
    line-height: 22px;
    max-height: 95px;

    &::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  &.type-no-word {
    .sendText_icon {
      color: #000;
      opacity: 0.3;

      &::before {
        color: inherit;
      }
    }
  }

  &.type-stop {
    .sendImg_icon {
      opacity: 0.3;
    }

    .sendText_icon {
      color: #000;
      opacity: 0.3;

      &::before {
        color: inherit;
      }
    }

    .input_text {
      color: rgba(0, 0, 0, 0.3);
      height: 32px;
      padding: 4px 12px;
    }
  }
}
</style>
