
import {
  computed,
  PropType,
  reactive,
  ref,
  toRefs,
} from 'vue';
import { useState } from '@/store/state';
import { IChatMessageModel } from '@/models/cs/api';
import { CsStatus } from '@/models/cs/enum';
import moment from 'moment';
import ChattingMessage from './ChattingMessage.vue';
import ChattingSender from './ChattingSender.vue';

export default {
  name: 'ChattingPanel',
  components: {
    ChattingMessage,
    ChattingSender,
  },
  setup(props: any) {
    const { modules } = useState();
    const chatWrapper = ref<HTMLElement | null>(null);
    const chatContent = ref<HTMLElement | null>(null);

    const data = reactive({
      dateMessage: {
        message: {
          quillDeltas: [
            {
              insert: moment().format('YYYY/MM/DD'),
            },
          ],
        },
      },
      isTerminated: computed(() => modules.chatting.state.status === CsStatus.Terminated),
    });

    const isLast = (index: number) => data.isTerminated && (index === props.histories.length - 1);
    const isFirst = (index: number) => !data.isTerminated && (index === 0);

    const resize = () => {
      if (chatWrapper.value) {
        chatWrapper.value!.scrollTop = chatWrapper.value!.scrollHeight;
      }
    };

    return {
      ...toRefs(data),
      chatWrapper,
      chatContent,
      isLast,
      isFirst,
      resize,
    };
  },
  props: {
    histories: {
      type: Array as PropType<IChatMessageModel[]>,
      default: () => [],
    },
  },
};
