<template>
  <section class="cs-with_title" >
    <div class="cs-title_box" :style="warn">
      <h2 class="title-lg title-qa">{{ index }}. {{ question.description }}</h2>
    </div>
    <div class="cs-select__wrapper" @click="onBlur">
      <select class="cs-select" v-model="answer" >
        <option :value="null">{{ $t('Survey_Select_Placeholder') }}</option>
        <option
          v-for="opt in question.surveyQuestionOptions"
          :key="`${question.questionId}_${opt.optionId}`"
          :value="opt.optionId"
        >{{ opt.values }}</option>
      </select>
    </div>
  </section>
</template>
<script>
import SurveyQuestionMixin from './SurveyQuestionMixin.vue';

export default {
  name: 'SurveySingleChoice',
  mixins: [SurveyQuestionMixin],
  computed: {
    answerRequest() {
      if (!this.answer) {
        return [];
      }
      const opt = this.question.surveyQuestionOptions.find((s) => s.optionId === this.answer);
      const request = {
        optionId: this.answer,
        optionText: opt ? opt.values : '',
      };
      return [request];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../scss/cs-style.scss";
@import '../../../css/style.css';
</style>
