import { Subject, timer } from 'rxjs';
import {
  App,
  h,
  createApp,
  VNode,
} from 'vue';
import { filter, mergeMap, tap } from 'rxjs/operators';
import {
  IMessage,
  MessageAction,
  MessageType,
  IMessageDialog,
  ICustomContent,
} from '@/models/message-center';

import MessagePop from '@/components/message-center/MessagePop.vue';
import MessageDialog from '@/components/message-center/MessageDialog.vue';
import MessagePrompt from '@/components/message-center/MessagePrompt.vue';
import { II18nStore } from './i18n';

interface IActionMessage {
  el: HTMLElement;
  app: App<Element>;
}

export default class MessageCenter {
  messageRxs = new Subject<IMessage>();

  messagePendingQueue = {
    pop: [] as IMessage[],
    prompt: [] as IMessage[],
  };

  actionMessage = {
    pop: [] as IActionMessage[],
    prompt: [] as IActionMessage[],
  };

  maxMessage = 1;

  constructor(public i18n: II18nStore) {
    this.initRx();
  }

  initRx() {
    this.messageRxs.pipe(
      mergeMap((x) => {
        switch (x.action) {
          case MessageAction.Remove:
            this.removeMessage(x.type);
            break;
          default:
            if (this.actionMessage[x.type].length === this.maxMessage) {
              this.messagePendingQueue[x.type].push(x);
            } else {
              this.popMessage(x);
            }
            break;
        }
        /* eslint no-nested-ternary: "off" */
        const durations = x.items ? (x.items.durations ? x.items.durations : 3000) : 3000;
        return timer(durations).pipe(
          tap(() => {
            this.removeMessage(x.type);
          }),
          filter(() => this.messagePendingQueue[x.type].length > 0),
          tap(() => {
            this.popMessage(this.messagePendingQueue[x.type].splice(0, 1)[0]);
          }),
        );
      }),
    ).subscribe();
  }

  $message(value: string | number | object | symbol, type?: MessageType) {
    const obj: IMessage = {
      type: type || 'pop',
      action: MessageAction.New,
      id: Symbol('message'),
    };
    const valueType = typeof value;
    if (valueType === 'string' || valueType === 'number') {
      obj.text = value as string;
    } else if (valueType === 'object') {
      obj.items = value as ICustomContent;
    } else if (valueType === 'symbol') {
      obj.action = MessageAction.Remove;
      obj.type = type ? 'prompt' : 'pop';
      obj.id = value as symbol;
    }
    this.messageRxs.next(obj);
  }

  private removeMessage = (type: MessageType) => {
    const item: IActionMessage = this.actionMessage[type].pop() as IActionMessage;
    if (item) {
      item.app.unmount(item.el);
    }
  }

  popDialog(s: IMessageDialog) {
    const comp = h(MessageDialog, s);

    const { app, appInstance } = this.appendBody(comp);
    return new Promise((resolve, reject) => {
      (appInstance.$data as any).result.then(() => {
        app.unmount(appInstance.$el);
        resolve();
      }).catch(() => {
        app.unmount(appInstance.$el);
        reject();
      });
    });
  }

  appendBody(comp: VNode) {
    const el = document.createElement('div');
    const app = createApp(comp); // .provide(i18nSymbol, this.i18n);
    app.config.globalProperties.$t = this.i18n.$t;
    const appInstance = app.mount(el);
    const e = appInstance.$el;
    document.body.appendChild(e);
    return { app, appInstance };
  }

  popMessage(msg: IMessage) {
    let comp;
    if (msg.type === 'pop') {
      comp = this.getPopComp(msg);
    } else {
      comp = this.getPromptComp(msg);
    }
    const { appInstance, app } = this.appendBody(comp);
    this.actionMessage[msg.type].push({ el: appInstance.$el, app });
  }

  getPopComp(msg: IMessage) {
    const { items, id } = msg;
    const props = {
      icon: (items && items.icon) ? items.icon : undefined,
      description: items ? items.description : undefined,
      id,
      durations: items?.durations,
      messageRxs: this.messageRxs,
    };
    const slot = msg.text ? [h('p', { class: 'pop' }, msg.text)] : null;
    return h(MessagePop, props, slot as any);
  }

  getPromptComp(msg: IMessage) {
    const { items, id } = msg;
    const props = {
      title: items ? items!.title : undefined,
      content: items ? items.content : undefined,
      description: items ? items.description : undefined,
      id,
      durations: 3000,
      messageRxs: this.messageRxs,
    };
    const slot = msg.text ? [h('p', { class: 'text__content' }, msg.text)] : null;
    return h(MessagePrompt, props, slot as any);
  }
}

export const messageCenterSymbol = Symbol('messageCenter');
