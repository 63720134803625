<template>
  <div class="popb__wrapper" :class="type">
    <div class="popb__body">
      <slot>
        <div class="popb__content">
          <div class="content__title" v-html="title" />
          <!-- <div class="popb__subtitle">{{ subtitle }}</div> -->
          <div class="content__subtitle-lg" v-if="subtitle">{{ subtitle }}</div>
          <div class="content__text" v-html="content" v-if="content" />
        </div>
      </slot>
      <slot name="footer">
        <div class="popb__bottom">
          <button class="button" v-if="isConfirm" @click="onCancel">{{ $t(cancelButtonText) }}</button>
          <button class="button" v-if="!isError" @click="onConfirm">{{ $t(confirmButtonText) }}</button>
        </div>
      </slot>
    </div>
    <label class="full_mask" style="display:block" @click="onClickModal"></label>
  </div>
</template>
<script>

export default {
  name: 'MessageDialog',
  props: {
    type: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    options: {
    },
    confirmButtonText: {
      type: String,
      default: 'Common_Message_Dialog_Confirm',
    },
    cancelButtonText: {
      type: String,
      default: 'Common_Message_Dialog_Cancel',
    },
  },
  data: () => ({
    config: {
      closeOnClickModal: false,
      lockScroll: true,
      // confirmButtonText: 'Confirm',
      // cancelButtonText: 'Cancel',
    },
    result: null,
    resolve: null,
    reject: null,
  }),
  computed: {
    isError() {
      return this.type === 'error';
    },
    isConfirm() {
      return this.type === 'confirm';
    },
    isWarn() {
      return this.type === 'warn';
    },
  },
  watch: {
  },
  created() {
    // this.config = Object.assign({}, this.config, this.options);
    if (this.isError) {
      this.config.closeOnClickModal = false;
    }
    this.lockBodyScroll();
    this.onListenPopState();
    this.result = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  },
  unmounted() {
    // document.body.removeChild(this.$el);
    window.removeEventListener('popstate', this.onConfirm);
    this.unLockBodyScroll();
  },
  methods: {
    unLockBodyScroll() {
      const dialogCount = document.getElementsByClassName('full_mask').length;
      if (dialogCount === 0) {
        document.body.classList.remove('full-open');
      }
    },
    lockBodyScroll() {
      if (this.config.lockScroll) {
        document.body.classList.add('full-open');
      }
    },
    onConfirm() {
      // this.$destroy();
      this.resolve();
    },
    onClickModal() {
      if (this.config.closeOnClickModal) {
        this.onCancel();
      }
    },
    onCancel() {
      // this.$destroy();
      this.reject('cancel');
    },
    onListenPopState() {
      window.addEventListener('popstate', this.onConfirm);
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '../../scss/main.scss';
  @import '../../scss/module/pop.scss';
</style>
