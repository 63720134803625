<script>
import { useState } from '@/store/state';
import CsWaiting from './CsWaiting.vue';
import CsActionBar from '../CsActionBar.vue';

export default {
  name: 'CsNotAvailable',
  mixins: [CsWaiting],
  components: { CsActionBar },
  setup() {
    const { modules } = useState();
    return {
      ...modules,
    };
  },
  mounted() {
    this.askForOfflineSurvey();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../css/style.css';
@import '../../../scss/cs-style.scss';
@import '../../../scss/module/cs-waiting.scss';
</style>
