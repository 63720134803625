export enum SpeakerType {
  Player = 0,
  User = 1,
  System = 2,
}

export enum SurveyType {
  PreChatSurvey = 0,
  ExitSurvey = 1,
  OfflineSurvey = 2,
}

export enum SurveyQuestionType {
  // DropDown = 0,
  SingleChoice = 1,
  MultiChoice = 2,
  // Boolean = 3,
  // Number = 4,
  // ShortAnswer = 5,
  Paragraph = 6,
}
export enum MessageType {
  Text = 0,
  Image = 1,
  Link = 2,
}
export interface IEnumToArray {
  id: number;
  name: string | number;
}

// 前端自己的status
export enum CsStatus {
  PreChatSurvey = 0,
  OfflineSurvey = 1,
  Dispatching = 2,
  Chatting = 3,
  ExitSurvey = 4,
  Terminated = 5,
  LibraryClosed = 6,
  CsNotAvailable = 7,
  RoomClosed = 8,
  PlayerLoginAndRegressRoom = 9,
  SpaClosed = 10,
}

export enum ChatEventType {
  None = 0,
  SendMessage = 1,
  // chatting-> close
  CloseRoom = 2,
  Maintenance = 3,
  CreateRoom = 4,
  CsOffline = 5,
  UserJoinRoom = 6,
  Remark = 7,
  // chatting-> history
  // close-> history
  UserRelease = 9,
  CsTransfer = 10,
  PlayerRegress = 11,
  QueueNumber = 12,
  RoomPending = 13,
  InspectDuplicate = 14,
  PlayerKickOut = 15,
  PrechatSurvey = 16,
  ExitSurvey = 17,
  Disconnected = 18,
  PlayerIgnore = 19,
}

export enum HubEventName {
  UserJoinAsync = 'userJoinAsync',
  MaintenanceAsync = 'maintenanceAsync',
  PlayerRegressAsync = 'playerRegressAsync',
  SpeakingAsync = 'speakingAsync',
  QueueNumberAsync = 'queueNumberAsync',
  StopRoomAsync = 'stopRoomAsync',
}
