
export default {
  name: 'BaseButton',
  props: {
    isDisabled: {
      type: [String, Boolean],
      default: false,
    },
  },
};
