import { Subject } from 'rxjs';

export type MessageType = 'pop' | 'prompt';
export enum MessageAction {
  New = 1,
  Remove = 2,
}

export interface IMessageDialog {
  options?: string;
  title?: string;
  content?: string;
  subtitle?: string;
  link?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  type: string;
}

export interface IMessageDetail {
  options?: string;
  slot: string;
  slotFooter: string;
  html: string;
}

export interface ICustomContent {
  durations?: number;
  icon?: string;
  title?: string;
  content?: string;
  description: string;
}
export interface IMessagePopProps {
  icon?: string;
  description?: string;
  type: MessageType;
  durations?: number;
  id?: symbol;
  messageRxs?: Subject<IMessage>;
}
export interface IMessagePromptProps {
  title: string | null;
  content: string | null;
  durations: number;
  type: MessageType;
  id: symbol;
  messageRxs: Subject<IMessage>;
}
export interface IMessage {
  type: MessageType;
  action: MessageAction;
  id: symbol;
  text?: string;
  items?: ICustomContent;
}
