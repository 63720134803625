<template>
  <div v-if="survey">
    <CsActionBar route="click"
      @click-left="closeChat"
      @click-right="onSkip"
      :disabled-right="isDisabled">
      <template v-slot:left>
        <span v-if="!isExitSurvey" class="custom__label">{{$t('Common_Cancel')}}</span>
      </template>
      <template v-slot:right>
        <span>{{$t('Common_Skip')}}</span>
      </template>
    </CsActionBar>
    <div class="cs-title_box align-left">
      <h1 class="title-xl">{{ survey.heading || 'Pre Chat Question' }}</h1>
      {{ survey.description }}
    </div>
    <div v-if="questions">
      <component
        v-for="(q, index) in questions"
        :key="q.questionId"
        :is="renderQuestion(q.surveyQuestionType)"
        :question="q"
        :index="index + 1"
        :ref="`q${index}`"
        @answer="onAnswer"
      />
    </div>
    <div class="cs-footer_box">
      <BaseButton
        @click="onSubmitSurvey"
        :isDisabled="isDisabled || !isAllAllowed"
      >
        {{$t('Common_Done')}}
      </BaseButton>
    </div>
  </div>
</template>
<script>
import { useState } from '@/store/state';
import {
  onUnmounted,
  computed,
  reactive,
  toRefs,
} from 'vue';
import {
  SurveyType,
  SurveyQuestionType,
  CsStatus,
} from '@/models/cs/enum';
import { chatApiHandler } from '@/services/chat-api-handler';
import CsActionBar from '../CsActionBar.vue';
import BaseButton from '../../base/BaseButton.vue';
import SurveyParagraph from './SurveyParagraph.vue';
import SurveySingleChoice from './SurveySingleChoice.vue';
import SurveyMultiChoice from './SurveyMultiChoice.vue';

export default {
  name: 'Survey',
  components: {
    CsActionBar,
    BaseButton,
    SurveyParagraph,
    SurveySingleChoice,
    SurveyMultiChoice,
  },
  setup() {
    const { modules } = useState();

    const data = reactive({
      surveyRecordId: null,
      isDisabled: false,
      isAllAllowed: false,
      answerStatus: [],
      status: computed(() => modules.chatting.state.status),
      skillId: computed(() => modules.chatting.state.skillId),
      roomId: computed(() => modules.chatting.state.roomId),
      survey: computed(() => modules.chatting.state.survey),
      isExitSurvey: computed(() => data.status === CsStatus.ExitSurvey),
      questions: computed(() => (data.survey && data.survey.surveyQuestions ? data.survey.surveyQuestions.filter((s) => SurveyQuestionType[s.surveyQuestionType]) : [])),
    });

    const renderQuestion = (type) => `Survey${SurveyQuestionType[type]}`;

    const onAnswer = (m) => {
      data.answerStatus[+m.index] = m.allow;
      data.isAllAllowed = data.answerStatus.every((s) => s);
    };

    const closeChat = () => {
      modules.chatting.state.isSpa ? modules.chatting.mutation.setStatus(CsStatus.SpaClosed) : modules.chatting.mutation.setStatus(CsStatus.LibraryClosed);
    };

    onUnmounted(() => {
      modules.chatting.mutation.setSurvey(null);
    });

    return {
      ...toRefs(data),
      ...modules,
      renderQuestion,
      onAnswer,
      closeChat,
    };
  },
  created() {
    this.getSurvey();
  },
  methods: {
    async getSurvey() {
      let type = SurveyType.PreChatSurvey;
      if (this.status === CsStatus.PreChatSurvey) {
        type = SurveyType.PreChatSurvey;
      } else if (this.status === CsStatus.ExitSurvey) {
        type = SurveyType.ExitSurvey;
      }
      await this.chatting.action.getSurvey({ type, skillId: this.skillId });
      if (!this.survey || this.questions.length === 0) {
        this.onSkip();
      }
      this.chatting.mutation.setLoader(false);
    },
    async onSubmitSurvey() {
      // CreateToken or OfflineSurvey
      const surveyAnswers = [];
      let checkRequired = true;
      Object.keys(this.$refs).forEach((s) => {
        const survey = this.$refs[s];
        const req = {
          questionId: survey.question.questionId,
          questionText: survey.question.description,
          surveyAnswerOptions: survey.answerRequest,
        };
        survey.blurred = true;
        surveyAnswers.push(req);
        if (!survey.isAllowSubmit) {
          checkRequired = false;
        }
      });
      if (checkRequired) {
        if (this.status === CsStatus.PreChatSurvey) {
          this.createRoomAndSendSurvey(surveyAnswers);
        } else {
          this.submitExitSurvey(surveyAnswers);
        }
      }
    },

    async createRoomAndSendSurvey(surveyAnswers) {
      const request = {
        answerSurvey: surveyAnswers ? { questions: surveyAnswers } : null,
      };
      if (this.chatting.state.isSpa) {
        const requestType = Number(this.$route.query.request_type) || 0;
        request.requestType = requestType;
      }
      this.isDisabled = true;
      const sendSurveyRes = await chatApiHandler.createRoomAndSendSurvey(request);
      if (!sendSurveyRes) return;
      if (sendSurveyRes.statusCode && sendSurveyRes.statusCode === 'PLAYER-10409') {
        this.$warn({
          content: this.$t('Survey_MaxLength_Warning'),
          confirmButtonText: this.$t('Common_Confirm'),
        });
        this.isDisabled = false;
        return;
      }
      const token = sendSurveyRes.data;
      const res = await chatApiHandler.checkPlayerInChat();
      if (!token || !res.data || !res.data.token) {
        this.$confirm({
          title: `${this.$t('Chatting_Support_Title_1')}<br>${this.$t('Chatting_Support_Title_2')}`,
          subTitle: '',
          content: this.$t('Chatting_Support_Content'),
          cancelButtonText: this.$t('Chatting_Support_Cancel'),
          confirmButtonText: this.$t('Chatting_Support_Confirm'),
          options: {
            closeOnClickModal: false,
          },
        }).then(() => {
          this.chatting.mutation.setStatus(CsStatus.OfflineSurvey);
        }).catch(() => {
          this.chatting.state.isSpa ? this.chatting.mutation.setStatus(CsStatus.SpaClosed) : this.chatting.mutation.setStatus(CsStatus.LibraryClosed);
        });
        return;
      }
      if (res.data) {
        this.chatting.mutation.setRoomId(res.data.roomId);
        this.chatting.mutation.setSkillId(res.data.skillId);
        this.chatting.mutation.setChatToken(token);
        // 有chatToken後會觸發dispatching, 如果這邊ws start失敗會直接進入closed
      }
    },
    async submitExitSurvey(surveyAnswers) {
      this.isDisabled = true;
      const request = {
        surveyType: SurveyType.ExitSurvey,
        roomId: this.roomId,
        skillId: this.skillId,
        questions: surveyAnswers,
      };
      const res = await chatApiHandler.submitExitSurvey(request);
      if (!res) return;
      if (res.statusCode && res.statusCode === 'PLAYER-10409') {
        this.$warn({
          content: this.$t('Survey_MaxLength_Warning'),
          confirmButtonText: this.$t('Common_Confirm'),
        });
        this.isDisabled = false;
        return;
      }
      this.chatting.state.isSpa ? this.chatting.mutation.setStatus(CsStatus.SpaClosed) : this.closeChat();
      this.isDisabled = false;
    },
    onSkip() {
      if (this.isExitSurvey) {
        this.chatting.state.isSpa ? this.chatting.mutation.setStatus(CsStatus.SpaClosed) : this.closeChat();
      } else {
        this.createRoomAndSendSurvey();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../scss/cs-style.scss";

</style>
