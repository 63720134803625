<template>
  <div>
    <CsActionBar route="click">
      <template v-slot:left>
        <span class="custom__label" @click="onClose">{{ $t('Common_Cancel') }}</span>
      </template>
    </CsActionBar>
    <div class="cs-title_box align-center">
      <h1 class="title-xl">{{ $t('Chatting_Support_Call') }}</h1>
      <p class="guidance">
        {{ $t('Chatting_Support_Busy_1') }}
        <template v-if="queue">{{ $t('Chatting_Support_Busy_2', { value: queue }) }}</template>
        {{ $t('Chatting_Support_Busy_3') }}
      </p>
    </div>
    <div class="circleProgressBar__wrapper">
      <div class="wave">
        <div class="avatar__wrapper">
          <i class="avatar_icon brand-icon-cs-avatar"/>
        </div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue';
import { chatApiHandler } from '@/services/chat-api-handler';
import { useState } from '@/store/state';
import { CsStatus, HubEventName } from '@/models/cs/enum';
import CsWaiting from './CsWaiting.vue';
import CsActionBar from '../CsActionBar.vue';
import { Subscription } from 'rxjs';

export default {
  name: 'CsDipatching',
  mixins: [CsWaiting],
  components: { CsActionBar },
  setup() {
    const { modules } = useState();
    const data = reactive({
      isMinimized: computed(() => modules.chatting.state.isMinimized),
      queue: null,
      subscription: new Subscription(),
    });

    return {
      ...toRefs(data),
      ...modules,
    };
  },
  props: {
    initQueueNumber: {
      type: [Number, null],
      default: null,
    },
  },
  created() {
    this.initDispatching();
  },
  methods: {
    onClose() {
      this.$confirm({
        title: `${this.$t('Chatting_Support_Stop_Title_1')}<br>${this.$t('Chatting_Support_Stop_Title_2')}`,
        content: this.$t('Chatting_Support_Stop_content'),
        cancelButtonText: this.$t('Common_Stop'),
        confirmButtonText: this.$t('Common_Proceed'),
        options: {
          closeOnClickModal: false,
        },
      }).catch(() => {
        this.clearSubs();
        this.closeRoom();
        this.askForOfflineSurvey({ roomClosed: true });
      });
    },
    clearSubs() {
      this.$socketFactory.ws?.off(HubEventName.UserJoinAsync);
      this.$socketFactory.ws?.off(HubEventName.QueueNumberAsync);
      this.subscription.unsubscribe();
    },
    async initDispatching() {
      this.chatting.mutation.setLoader(false);
      if (this.chatting.state.isSpa) {
        this.queue = this.initQueueNumber || 1;
        this.subscription.add(
          this.$socketFactory.ws?.on(HubEventName.QueueNumberAsync).subscribe(() => {
            if (this.queue > 1) {
              this.queue -= 1;
            }
          }),
        );
      } else {
        const initQueueNumber = await chatApiHandler.getInitialQueueNumber();
        if (initQueueNumber === 0) {
          this.chatting.mutation.setStatus(CsStatus.Chatting);
        }
        this.queue = initQueueNumber || 1;
        this.queueSubs = this.$socketFactory.ws?.on(HubEventName.QueueNumberAsync).subscribe(() => {
          if (this.queue > 1) {
            this.queue -= 1;
          }
        });
      }
      this.subscription.add(
        this.$socketFactory.ws?.on(HubEventName.UserJoinAsync).subscribe(() => {
          this.chatting.mutation.setStatus(CsStatus.Chatting);
        }),
      );
    },
  },
  unmounted() {
    this.clearSubs();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../css/style.css';
@import '../../../scss/cs-style.scss';
@import "../../../scss/module/cs-waiting.scss";
</style>
