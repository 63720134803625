
export default {
  name: 'CsActionBar',
  setup(props: any, context: any) {
    const onClickLeft = () => {
      if (props.route === 'click') {
        context.emit('click-left');
      }
    };
    const onClickRight = () => {
      context.emit('click-right');
    };
    return {
      onClickLeft,
      onClickRight,
    };
  },
  props: {
    route: {
      type: String,
      default: 'click',
    },
    showLeft: {
      type: Boolean,
      default: true,
    },
    backLabel: {
      type: String,
    },
    disabledRight: {
      type: Boolean,
      default: false,
    },
  },
};
