import { reactive, readonly, inject } from 'vue';
import {
  IBrandStore,
  IPlayerInfoState,
  IBrandMutation,
  IBrandState,
} from '@/models/store';
import { chattingStore } from './chat-system';

export const stateSymbol = Symbol('brandStore');

export const CustomEventDispatcher = (event: string, detail?: { [key: string]: any }) => {
  window.dispatchEvent(new CustomEvent(event, { detail }));
};

export const createStore = (): IBrandStore => {
  const playerInfo: IPlayerInfoState = {
    isLogged: null,
  };

  // State
  const state: IBrandState = reactive({
    playerInfo,
    culture: '',
  });

  const mutation: IBrandMutation = {
    setLoginStatus: (status: boolean) => {
      state.playerInfo.isLogged = status;
    },
    setCulture: (culture: string) => {
      state.culture = culture;
    },
  };
  return {
    state: readonly(state) as IBrandState,
    mutation,
    modules: {
      chatting: chattingStore,
    },
  };
};

export const useState = (): IBrandStore => inject(stateSymbol) as IBrandStore;
