<template >
  <router-view />
</template>

<style lang="scss">
$max-width: 480px;
@media only screen and (min-width: 480px) {
  body {
    background-color: #EBEBEB;
    .img-viewer {
      max-width: $max-width;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
}
 </style>
