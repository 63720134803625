<template>
  <div
    class="loading-icon"
    :style="`width: ${perimeter}; height: ${perimeter}; background-color: ${backgroundColor};`"
    :class="trackTheme">
    <div class="pie"></div>
    <div class="pie"></div>
    <div class="pie"></div>
    <div class="pie"></div>
    <div class="center-mask"></div>
  </div>
</template>
<script>
export default {
  name: 'BaseLoading',
  props: {
    perimeter: {
      type: String,
      default: '52px',
    },
    trackTheme: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'inherit',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';

.loading-icon {
  font-size: 0;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  position: relative;
  animation: load3 1s infinite linear;
  background: conic-gradient(#d90101, rgba(217, 1, 1, 0.98), rgba(217, 1, 1, 0.49), rgba(217, 1, 1, 0.27), rgba(217, 1, 1, 0.1));
  &.light {
    background: conic-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0),);
    .center-mask {
      width: 75%;
      height: 75%;
    }
  }
  .center-mask {
    position: absolute;
    width: 70%;
    height: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: inherit;
    border-radius: 50%;
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
