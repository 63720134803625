
import { computed } from 'vue';
import { useState } from '@/store/state';
import SurveyQuestionMixin from './SurveyQuestionMixin.vue';

export default {
  name: 'SurveyParagraph',
  mixins: [SurveyQuestionMixin],
  setup() {
    const { state } = useState();
    const maxlengthByCulture = computed(() => (state.culture === 'vi-vn' ? 300 : 100));

    return {
      maxlengthByCulture,
    };
  },
};
