import { readonly, reactive } from 'vue';
import {
  IChattingStore,
  IChattingState,
  IChattingMutation,
  IChattingAction,
} from '@/models/cs/store';
import { CsStatus } from '@/models/cs/enum';
import {
  IGetSurveyRequest,
  IGetSurveyResponse,
} from '@/models/cs/api';
import { chatApiHandler } from '@/services/chat-api-handler';

const CustomEventDispatcher = (event: string, detail: { [key: string]: any }) => {
  window.dispatchEvent(new CustomEvent(event, { detail }));
};

const state: IChattingState = reactive({
  isSpa: process.env.VUE_APP_IS_SPA === 'true',
  unReadCount: 0,
  isMinimized: true,
  skillId: null,
  roomId: null,
  status: null,
  survey: null,
  chatToken: null,
  isLoader: false,
});

const mutation: IChattingMutation = {
  setStatus(status: CsStatus) {
    state.status = status;
    // 給brand component
    CustomEventDispatcher('getCsStatus', { status });
  },
  setMinimized(isMinimized: boolean) {
    const chatInstance = document.getElementById('chat-system');
    state.isMinimized = isMinimized;
    if (isMinimized && state.status !== CsStatus.LibraryClosed) {
      chatInstance!.classList.add('on-chat');
    }
    if (!isMinimized && chatInstance!.classList.contains('on-chat')) {
      chatInstance!.classList.remove('on-chat');
    }
  },
  setUnReadCount(count: number) {
    state.unReadCount = count;
  },
  setSkillId(id: string) {
    state.skillId = id;
  },
  setSurvey(survey: IGetSurveyResponse | null) {
    state.survey = survey;
  },
  setRoomId(id: string) {
    state.roomId = id;
  },
  setChatToken(chatToken: string) {
    state.chatToken = chatToken;
  },
  setLoader(isShow: boolean) {
    state.isLoader = isShow;
  },
};

const action: IChattingAction = {
  getSurvey: async (request: IGetSurveyRequest) => {
    const res = await chatApiHandler.getSurvey(request);
    if (res) {
      mutation.setSurvey(res);
    }
  },
};

export const chattingStore: IChattingStore = {
  state: readonly(state) as IChattingState,
  mutation,
  action,
};

export default chattingStore;
