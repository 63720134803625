
import { computed } from 'vue';
import { useI18n } from '@/services/i18n';
import { SpeakerType } from '@/models/cs/enum';
import { IChatMessageModel } from '@/models/cs/api';
import ChattingMessage from './ChattingMessage.vue';
import ChattingSender from './ChattingSender.vue';

export default {
  name: 'RoomClosed',
  components: { ChattingMessage, ChattingSender },
  setup() {
    const { $t } = useI18n();
    const message = computed((): IChatMessageModel => ({
      speakerType: SpeakerType.System,
      message: {
        quillDeltas: [
          {
            insert: $t('Chatting_Closed_By_Cs'),
          },
        ],
      },
    }));

    return {
      message,
    };
  },
};
