import {
  reactive,
  readonly,
  inject,
} from 'vue';

export const i18nSymbol = Symbol('i18n');

export interface II18nState {
  locale: string;
  messages: { [key: string]: string };
}
type Currency ='cny' | 'vnd' | 'thb';
export interface II18nStore {
  readonly $i18n: II18nState;
  $t: (key: string, variable?: { [key: string]: string }) => string;
  $te: (key: string) => boolean;
  setLocale: (culture: string) => void;
}
export const createI18n = (): II18nStore => {
  const $i18n: II18nState = reactive({
    locale: '',
    messages: {},
  });
  const setLocale = async (culture: string) => {
    const m = await import(/* webpackChunkName: "brand-lang-[request]" */ `@/lang/${culture}.json`);
    if (m) {
      $i18n.locale = culture;
      $i18n.messages = m;
    }
  };
  const $t = (key: string, variable?: { [key: string]: string }): string => {
    if (!$i18n.locale) {
      return '';
    }
    if (variable) {
      return $i18n.messages[key].replace(/{(.*?)}/g, (a, b) => variable[b]);
    }
    return $i18n.messages[key] || key;
  };
  const $te = (key: string): boolean => !!$i18n.messages[key];

  return {
    $i18n: readonly($i18n), $t, $te, setLocale,
  };
};

export const useI18n = (): II18nStore => inject(i18nSymbol) as II18nStore;
