
import { PropType, defineComponent } from 'vue';
import { MessageAction, IMessage } from '@/models/message-center';
import { Subject } from 'rxjs';

export default defineComponent({
  name: 'MessagePrompt',
  setup(props) {
    const removeFromBody = () => {
      const obj: IMessage = {
        action: MessageAction.Remove,
        type: 'prompt',
        id: props.id as symbol,
      };
      props.messageRxs!.next(obj);
    };
    const onClick = () => {
      removeFromBody();
    };
    return { onClick, removeFromBody };
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    durations: {
      type: Number,
      default: 3000,
    },
    id: Symbol,
    messageRxs: Object as PropType<Subject<IMessage>>,
  },
});
