import { ref } from 'vue';

export default function useImageCache() {
  // key(url), value(base64)
  const imageCacheMap = ref(new Map<string, string>());

  async function getBase64FromUrl(url: string): Promise<string | null> {
    const data = await fetch(url, {
      headers: {
        Accept: 'image/*',
        'Content-Type': 'image/*',
      },
    });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
    });
  }

  // returns the value(base64) by the key
  function getImageCacheMap(url: string): string | undefined {
    return imageCacheMap.value.get(url);
  }

  // getBase64FromUrl & stores the value(base64) by the key(url)
  async function setImageCacheMap(url: string) {
    const base64 = await getBase64FromUrl(url);
    if (base64 && base64.includes('data:image/')) {
      imageCacheMap.value.set(url, base64);
    }
  }

  return {
    getImageCacheMap,
    setImageCacheMap,
  };
}
