
import { computed, defineComponent, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useState } from '@/store/state';
import { useI18n } from '@/services/i18n';
import { chatApiHandler } from '@/services/chat-api-handler';
import { IChatMessageModel, IRoomHistoryModel, ISendMessageEvent } from '@/models/cs/api';
import { CsStatus, HubEventName, SpeakerType } from '@/models/cs/enum';
import ChattingPanel from './ChattingPanel.vue';
import { SocketFactory } from '@/services/SocketFactory';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

interface IChattingPanel {
  chatWrapper: HTMLElement;
  chatContent: HTMLElement;
}

export default defineComponent({
  name: 'Chatting',
  components: { ChattingPanel },
  setup() {
    const { modules } = useState();
    const { $t } = useI18n();
    const panelRef = ref<IChattingPanel | null>(null);
    const isMinimized = computed(() => modules.chatting.state.isMinimized);
    const unReadCount = computed(() => modules.chatting.state.unReadCount);
    const status = computed(() => modules.chatting.state.status);
    const chatHistories = ref<IChatMessageModel[]>([]);
    const isShownUnReadSystemMessage = ref<boolean>(false);
    const subscription = new Subscription();
    const scrollToBottomSubject = new Subject<void>();

    const unReadSystemMessage = {
      speakerType: SpeakerType.System,
      message: {
        quillDeltas: [
          {
            insert: $t('Common_Unread_Below'),
          },
        ],
      },
      playerRead: false,
      isUnReadSystemMessage: true,
    };

    const clearSubscription = (socketFactory: SocketFactory) => {
      socketFactory.ws?.off(HubEventName.SpeakingAsync);
      subscription.unsubscribe();
    };

    const scrollToBottom = () => {
      panelRef.value!.chatWrapper.scrollTop = panelRef.value!.chatWrapper.scrollHeight;
    };

    scrollToBottomSubject.pipe(
      debounceTime(100)
    ).subscribe(() => {
      scrollToBottom();
    });

    const getRoomHistory = async () => {
      const res = await chatApiHandler.getRoomHistory();
      if (res) {
        chatHistories.value = res.map((item: IRoomHistoryModel): IChatMessageModel => {
          return {
            messageId: item.messageId,
            speakerType: item.speakerType,
            message: item.message,
            createdDate: item.createdDate,
            playerRead: item.playerRead,
          };
        });
        const unReadIndex = res.findIndex((item: IRoomHistoryModel) => !item.playerRead);
        const unReadLength = res.filter((item: IRoomHistoryModel) => !item.playerRead).length;
        modules.chatting.mutation.setUnReadCount(unReadLength);
        if (unReadIndex >= 0) {
          chatHistories.value.splice(unReadIndex, 0, unReadSystemMessage);
          isShownUnReadSystemMessage.value = true;
        }
      }
    };

    const setMessageIsRead = () => {
      // 移除未讀提示文字 & 每一項目壓成已讀
      chatHistories.value = chatHistories.value
        .filter((item: IChatMessageModel) => !item.isUnReadSystemMessage)
        .map((item: IChatMessageModel) => {
          // eslint-disable-next-line no-param-reassign
          item.playerRead = true;
          return item;
        });
      isShownUnReadSystemMessage.value = false;
    };

    const initMessageSubs = (socketFactory: SocketFactory) => {
      subscription.add(
        socketFactory.ws?.on<ISendMessageEvent>(HubEventName.SpeakingAsync).subscribe(messageObj => {
          const chatMessage: IChatMessageModel = {
            messageId: messageObj.messageId,
            speakerType: messageObj.speakerType,
            message: messageObj.message,
            createdDate: messageObj.createdDate,
            playerRead: false,
          };
          if (!isMinimized.value) {
            chatMessage.playerRead = true;
          } else {
            if (!isShownUnReadSystemMessage.value) {
              chatHistories.value.push(unReadSystemMessage);
            }
            isShownUnReadSystemMessage.value = true;
            modules.chatting.mutation.setUnReadCount(unReadCount.value + 1);
          }
          chatHistories.value.push(chatMessage);
        }),
      );
    };

    const setRoomDisplayStatus = async () => {
      await chatApiHandler.setRoomDisplayStatus({ isNarrow: isMinimized.value });
    };

    watch(isMinimized, async (v: boolean) => {
      if (status.value === CsStatus.LibraryClosed || status.value === CsStatus.Terminated) {
        return;
      }
      // 狀態別要打API, 如果是開啟的狀態就不用紀錄unread, 且清除unRead
      // 如果是關閉的狀態就要記錄unread
      await setRoomDisplayStatus();
      if (v) {
        setMessageIsRead();
      } else {
        const chatHistoriesCopy = chatHistories.value.slice();
        const lastMessage = chatHistoriesCopy.reverse().find((item) => item.messageId);
        const unReadIndex = chatHistoriesCopy.findIndex((item: IChatMessageModel) => item.playerRead === false);
        if (lastMessage && lastMessage.messageId && unReadIndex >= 0) {
          await chatApiHandler.clearUnRead(lastMessage.messageId);
        }
        modules.chatting.mutation.setUnReadCount(0);
      }
    });

    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === panelRef.value?.chatContent) {
          scrollToBottomSubject.next();
        }
      }
    };

    let resizeObserver: ResizeObserver;

    onBeforeUnmount(() => {
      const { $socketFactory } = getCurrentInstance()!.appContext.config.globalProperties;
      clearSubscription($socketFactory);
      scrollToBottomSubject.unsubscribe;
      if (resizeObserver && panelRef.value?.chatContent) {
        resizeObserver.unobserve(panelRef.value?.chatContent);
      }
    });

    onMounted(() => {
      if (panelRef.value?.chatContent) {
        resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(panelRef.value?.chatContent);
      }
      const { $socketFactory } = getCurrentInstance()!.appContext.config.globalProperties;
      getRoomHistory();
      initMessageSubs($socketFactory);
      setRoomDisplayStatus();
    });

    return {
      panelRef,
      ...modules,
      chatHistories,
      getRoomHistory,
      scrollToBottomSubject,
    };
  },
});
