export const touch = {
  mounted: (el: HTMLElement) => {
    let startY: number;
    el.classList.add('ct-touch');
    el.addEventListener('touchstart', (event) => {
      event.stopPropagation();
      if (event.targetTouches.length > 1) {
        return;
      }
      startY = event.targetTouches[0].clientY;
    }, false);
    el.addEventListener('touchmove', (event) => {
      event.stopPropagation();
      if (event.targetTouches.length > 1) {
        return;
      }
      const y = event.targetTouches[0].clientY - startY;
      if (el.scrollTop <= 0 && y > 0) {
        event.preventDefault();
        return;
      }
      const maxScrollTop = el.scrollHeight - el.clientHeight;
      if (maxScrollTop - el.scrollTop <= 0 && y < 0) {
        event.preventDefault();
      }
    }, { passive: false });
    document.body.ontouchmove = (event) => {
      event.preventDefault();
    };
    document.body.classList.add('full-open-casual');
    const appEle = document.getElementById('app');
    if (appEle) {
      appEle.ontouchmove = (event) => {
        event.preventDefault();
      };
      appEle.classList.add('full-open-casual');
    }
  },
  unmounted: (el: HTMLElement) => {
    el.classList.remove('ct-touch');
    if (document.querySelectorAll('.ct-touch').length === 0) {
      document.body.ontouchmove = null;
      document.body.classList.remove('full-open-casual');
      const appEle = document.getElementById('app');
      if (appEle) {
        document.getElementById('app')!.ontouchmove = null;
        document.getElementById('app')!.classList.remove('full-open-casual');
      }
    }
  },
};

export default touch;
