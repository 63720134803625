<template>
  <div class="img-viewer" v-if="modelValue">
    <i class="brand-icon-cross close_btn" @click.stop="onClickClose"></i>
    <div class="container__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImageViewer',
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
    modelValue: {
      handler: 'onValueChange',
      immediate: true,
    },
  },
  created() {
    this.onListenPopState();
  },
  mounted() {
    if (this.value) {
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
      }
    }
  },
  methods: {
    onClickClose() {
      this.$emit('update:modelValue', false);
    },
    onValueChange(v) {
      if (v && this.$el) {
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      }
    },
    onListenPopState() {
      window.addEventListener('popstate', this.onClickClose);
    },
  },
  unmounted() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    window.removeEventListener('popstate', this.onClickClose);
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
@import '../../css/style.css';

// 蓋版
%full {
  @include w-h;
  position: fixed;
  top: 0;
  left: 0;
}

.img-viewer {
  @extend %full;
  z-index: 2610; // 需要至少比 pp full-container 的icon_box高
  background-color: #515151;
  display: flex;
  .brand-icon-cross::before {
    color: #fff;
  }
  .container__inner {
    @include w-h;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 24px 24px 24px;
    .img {
      max-width: 100%;
      max-height: 100%;
      z-index: 1;
    }
  }
}

.close_btn {
  width: 30px;
  height: 30px;
  z-index: 10;
  @include position-tr(10px, 10px, absolute);
  &:before {
    @include position-tl(50%, 50%, absolute);
    transform: translate(-50%, -50%);
  }
}

</style>
