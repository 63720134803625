
import { defineComponent, PropType } from 'vue';
import { Subject } from 'rxjs';
import { IMessage, MessageAction } from '@/models/message-center';

export default defineComponent({
  name: 'MessagePop',
  setup(props) {
    const removeFromBody = () => {
      const obj: IMessage = {
        action: MessageAction.Remove,
        type: 'pop',
        id: props.id as symbol,
      };
      props.messageRxs!.next(obj);
    };
    const onClick = () => {
      removeFromBody();
    };
    return { onClick, removeFromBody };
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    durations: {
      type: Number,
      default: 3000,
    },
    id: Symbol,
    messageRxs: Object as PropType<Subject<IMessage>>,
  },
});
