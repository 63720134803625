<template>
  <div ref="uploader" class="split-uploader">
    <div class="images-preview__wrapper" @click.stop v-if="preview">
      <div v-for="(item, index) in imageList" :key="index"  class="images-preview">
        <img style="width:100%; height:100%" :src="item.base64">
        <div class="remove_button" @click="remove(item.uniqueIdentifier)">{{ item.progress === 1 ? $t('Common_Remove') : item.progress }}</div>
      </div>
    </div>
    <slot :isOverLimit="isOverLimit" :isWrongType="isWrongType">
      <div class="input input_file">
        <label for="input_img">
          <div class="input__inner">
            <i class="brand-icon-image upload-icon" />
            <span class="description">
              <slot>
                <template>
                  {{$t('Common_UploadFile_Button')}}
                </template>
              </slot>
            </span>
            <i class="brand-icon-chevron-right chevron-right" />
          </div>
        </label>
        <div class="warn-tip" v-if="isOverLimit">{{$t('Common_UploadFile_OverLimit_Warning')}}</div>
        <div class="warn-tip" v-if="isWrongType">{{$t('Common_UploadFile_WrongType_Warning')}}</div>
      </div>
    </slot>
  </div>
</template>

<script>
import Resumable from 'resumablejs';
import { useI18n } from '@/services/i18n';

export default {
  name: 'BaseSplitUploader',
  props: {
    route: {
      type: String,
      default: '/api/image/upload',
    },
    maxFiles: {
      type: Number,
      default: 3,
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    chunkSize: {
      type: Number,
      default: 1 * 512 * 1024,
    },
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024,
    },
    fileType: {
      type: Array,
      default: () => ['png', 'jpeg', 'jpg', 'bmp'],
    },
  },
  data: () => ({
    images: {},
    resumable: null,
    isOverLimit: false,
    isWrongType: false,
  }),
  computed: {
    imageList() {
      const list = Object.keys(this.images).map((s) => this.images[s]);
      this.$emit('input', list);
      this.$emit('uploading', this.isUploading);
      return list;
    },
    isUploading() {
      return Object.keys(this.images).map((s) => this.images[s].progress).some((s) => s < 1);
    },
  },
  mounted() {
    const { $t } = useI18n();
    const config = {
      target: this.route,
      query: {},
      maxChunkRetries: 2,
      generateUniqueIdentifier: (file) => (`${file.name}_${new Date().toISOString()}`).replace(/ /g, ''),
      maxFiles: this.maxFiles,
      testChunks: false,
      forceChunkSize: true,
      prioritizeFirstAndLastChunk: true,
      simultaneousUploads: 1,
      chunkSize: this.chunkSize,
      fileType: this.fileType,
      maxFileSize: this.maxFileSize,
    };
    if (this.preview) {
      config.fileTypeErrorCallback = () => {
        this.isWrongType = true;
        this.isOverLimit = false;
      };
    }
    config.maxFileSizeErrorCallback = (file) => {
      alert(this.$t('Chatting_UploadFile_OverLimit_Warning', { value: file.name }));
      this.isOverLimit = true;
      this.isWrongType = false;
    };
    this.resumable = new Resumable(config);
    this.resumable.assignBrowse(this.$refs.uploader);
    this.resumable.on('fileAdded', this.onAddFile);
    this.resumable.on('fileSuccess', this.onSuccess);
    this.resumable.on('fileProgress', this.onProgress);
    this.resumable.on('fileError', this.onFail);
    this.resumable.on('chunkingStart', () => {
      this.isOverLimit = false;
      this.isWrongType = false;
    });
    this.$refs.uploader.disable = true;

    this.setInputAttr();
  },
  methods: {
    setInputAttr() {
      const imgDom = this.$refs.uploader.querySelector('input');
      if (!imgDom) {
        return;
      }
      imgDom.setAttribute('accept', this.fileType.map((e) => {
        const type = e.replace(/\s/g, '').toLowerCase();
        return `image/${type}`;
      }).join(','));
    },
    fileOnLoad(uniqueIdentifier, event) {
      const img = new Image();
      img.onload = () => {
        let { width, height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        width = Math.round(width / 3);
        height = Math.round(height / 3);
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL('image/jpeg', 0.5);
        if (this.images[uniqueIdentifier]) {
          this.images[uniqueIdentifier].base64 = base64;
          // $set(this.images[uniqueIdentifier], 'base64', base64);
          this.$emit('change', this.images[uniqueIdentifier]);
        }
      };
      img.src = event.target.result;
    },
    onProgress(file, message) {
      this.images[file.uniqueIdentifier].progress = file.progress();
      // $set(this.images[file.uniqueIdentifier], 'progress', file.progress());
      this.$emit('progress', {
        uniqueIdentifier: file.uniqueIdentifier,
        progress: file.progress(),
      });
    },
    onFail(file) {
      delete this.images[file.uniqueIdentifier];
      this.$emit('change', file.uniqueIdentifier);
      this.resumable.removeFile(file);
    },
    onAddFile(file, f) {
      if (this.isWrongType || this.isOverLimit) {
        this.resumable.removeFile(file);
        return;
      }
      this.images[file.uniqueIdentifier] = {
        imageId: null,
        fileName: file.fileName.substr(0, 50),
        progress: 0,
        uniqueIdentifier: file.uniqueIdentifier,
      };
      // $set(this.images, file.uniqueIdentifier, {
      //   imageId: null,
      //   fileName: file.fileName.substr(0, 50),
      //   progress: 0,
      //   uniqueIdentifier: file.uniqueIdentifier,
      // });
      this.$emit('uploading', true);
      if (this.thumbnail || this.preview) {
        const reader = new FileReader();
        reader.onload = this.fileOnLoad.bind(null, file.uniqueIdentifier);
        reader.readAsDataURL(file.file);
      }
      this.$emit('change', this.images[file.uniqueIdentifier]);
      this.resumable.upload();
    },
    async onSuccess(file) {
      let imageId;
      file.chunks.every((s, i) => {
        const id = JSON.parse(s.xhr.response).data;
        if (id) {
          imageId = id;
          return false;
        }
        return true;
      });
      this.images[file.uniqueIdentifier].imageId = imageId;
      // $set(this.images[file.uniqueIdentifier], 'imageId', imageId);
      this.$emit('change', this.images[file.uniqueIdentifier]);
    },
    remove(uniqueIdentifier) {
      delete this.images[uniqueIdentifier];
      const file = this.resumable.getFromUniqueIdentifier(uniqueIdentifier);
      this.resumable.removeFile(file);
    },
    getImages() {
      return this.imageList;
    },
  },
};

</script>

<style lang="scss" scoped>

.images-preview__wrapper {
  padding: 0 30px;
  .images-preview {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 192px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    .remove_button {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #FFF;
      padding: 10px 12px;
      border-radius: 10px;
      font-size: 14px;
    }
    &+.images-preview {
      margin-top: 10px;
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }
}
i.pp-co-icon-chevron-right {
  position: relative;
  width: 12px;
  height: 12px;
}
.pp-co-icon-chevron-right::before {
  color: #B3B3B3;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
}
.warn-tip {
  color: red;
  margin-top: 10px;
  text-align: center;
}
</style>
