<template>
  <div>
    <CsActionBar route="click">
      <template v-slot:left>
        <span class="custom__label">{{$t('Common_Cancel')}}</span>
      </template>
    </CsActionBar>
    <div class="cs-title_box align-center">
      <h1 class="title-xl">{{$t('Chatting_Support_Call')}}</h1>
      <p class="guidance">
        {{$t('Chatting_Support_Busy_1')}}
        <template v-if="queue">{{$t('Chatting_Support_Busy_2', {value : queue})}}</template>
        {{$t('Chatting_Support_Busy_3')}}
      </p>
    </div>
    <div class="circleProgressBar__wrapper">
      <div class="wave">
        <div class="avatar__wrapper">
          <i class="avatar_icon brand-icon-cs-avatar" />
        </div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { useState } from '@/store/state';
import { CsStatus } from '@/models/cs/enum';
import { chatApiHandler } from '@/services/chat-api-handler';
import CsActionBar from '../CsActionBar.vue';

export default {
  name: 'CsWaiting',
  components: { CsActionBar },
  setup() {
    const { modules } = useState();

    return {
      ...modules,
    };
  },
  data: () => ({
    queue: null,
  }),
  methods: {
    askForOfflineSurvey(options = { roomClosed: false }) {
      const { roomClosed } = options;
      this.$confirm({
        title: `${this.$t('Chatting_Support_Title_1')}<br>${this.$t('Chatting_Support_Title_2')}`,
        subTitle: '',
        content: this.$t('Chatting_Support_Content'),
        cancelButtonText: this.$t('Chatting_Support_Cancel'),
        confirmButtonText: this.$t('Chatting_Support_Confirm'),
        options: {
          closeOnClickModal: false,
        },
      }).then(() => {
        this.chatting.mutation.setStatus(CsStatus.OfflineSurvey);
        if (!roomClosed) {
          this.closeRoom();
        }
      }).catch(() => {
        if (this.chatting.state.isSpa) {
          this.chatting.mutation.setStatus(CsStatus.SpaClosed);
        } else {
          this.chatting.mutation.setStatus(CsStatus.LibraryClosed);
          if (!roomClosed) {
            this.closeRoom();
          }
        }
      });
    },
    async closeRoom() {
      if (this.chatting.state.roomId) {
        await chatApiHandler.closeRoom();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../scss/cs-style.scss";
@import "../../../scss/module/cs-waiting.scss";
@import '../../../css/style.css';
</style>
